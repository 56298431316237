<template>
  <b-row class="pt-1">
    <b-col md="5">
      <div class="d-flex align-items-center">
        <b-img
          fluid
          src="@/assets/images/logo/logo.png"
          alt="Login V2"
          :width="70"
        />
        <h1 class="text-yellow px-2 font-weight-bold font-xxl">PENSA GHANA</h1>
      </div>
       <div>
          <h2 style="color: white;">Who we are</h2>
          <div class="text-white" style="font-size: 13px">
            <span class="font-weight-bold text-white" style="color: white">PENSA</span> is a vibrant student wing of The Church of Pentecost Youth Ministry. Present in almost all tertiary and second cycle schools in Ghana, we are strategically positioned to holistically empower young people to be impactful agents of transformation in all sphere of life they may find themselves.
          </div>
            <div class="mt-2 text-white" style="font-size: 13px">
               We go with the slogan <br />
              <span style="font-weight: 900;">&ldquo; Christ in you!! The hope of glory &rdquo;</span>
            </div>

            <b-button style="background-color: #f0d110 !important;" :to="{ name: 'about-us' }">Read More</b-button>
        </div>
    </b-col>
    <b-col md="3" class="mt-2">
      <div>
        <h2 class="text-white font-weight-bold">
          QUICK ACCESS
        </h2>

        <div class="d-flex flex-column mb-2 mt-1" style="gap: 5px">
          <router-link :to="{ name: '/' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            HOME
          </router-link>
          <router-link :to="{ name: 'about-us-history' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            HISTORY
          </router-link>
          <router-link :to="{ name: 'about-us-leadership' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            LEADERSHIP
          </router-link>
          <router-link :to="{ name: 'about-us-campus' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            CAMPUSES
          </router-link>
          <router-link :to="{ name: 'about-us-sectors' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            SECTORS
          </router-link>
          <router-link :to="{ name: 'events' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            EVENTS/ACTIVITIES
          </router-link>
          <router-link :to="{ name: 'blog' }" class="d-inline-block mr-1 quick-link" href="#" rel="noopener noreferrer">
            BLOG
          </router-link>
        </div>
        <!-- <div class="side-note">
          <h1 style="color: white; font-weight: 900; font-size: 30px;">CAMPUS<br /> TAKE OVER</h1>
          <p style="color: white;">We are in 250 schools Accross <br /> Ghana, both tertiary and <br /> SHS Schools</p>
        </div> -->
      </div>
    </b-col>
    <b-col md="4" class="mt-2">
        <h2 class="text-white font-weight-bold">
          OUR BLOG
        </h2>

        <FooterBlogs />

        <h2 style="color: #ee151b;">
          CONNECT WITH US
        </h2>

        <div class="mb-2 mt-1">
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="FacebookIcon" size="20" color="white"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="TwitterIcon" size="20" color="white"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="InstagramIcon" size="20" color="white"></feather-icon>
          </a>
          <a class="d-inline-block mr-1" href="#" target="_blank" rel="noopener noreferrer">
            <feather-icon icon="YoutubeIcon" size="20" color="white"></feather-icon>
          </a>
        </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
  BButton
} from 'bootstrap-vue';
import AppLogo from '@core/layouts/components/Logo.vue';
import FooterBlogs from '@core/layouts/components/FooterBlogs.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    AppLogo,
    FooterBlogs,
    BButton
  }
}
</script>

<style lang="scss" scoped>
.text-yellow {
  color: yellow
}
.font-md {
  font-size: 12px;
}
.font-lg {
  font-size: 14px;
}
.font-xxl {
  font-size: 25px;
}
h1, h2, h3, h4 {
  font-weight: normal;
}

.quick-link {
  color: white;
  font-size: medium;
  font-weight: bold;
}
</style>
