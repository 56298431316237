<template>
  <section class="mt-2 pb-5" style="max-width: 270px;">
    <div v-for="(blog, i) in blogs" :key="i" class="d-flex" style="gap: 5px;">
      <b-img 
      width="100"
      height="70"
      style="border-radius: 10px;"
      :src="getActualValueFromSource(blog, 'featured_image.path', require('@/assets/images/pages/blog-default.webp'))"
      />
      <router-link :to="{ name: 'single-blog', params: { slug: getValueFromSource(blog, 'slug')} }">
        <div style="max-height: 100px;">
          <h3 class="text-white -ellipsis-2" :title="blog.title">{{ blog.title }}</h3>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="text-white -ellipsis-2">{{ extractTextContentFromHTML(blog.content) }}</p>
        </div>
      </router-link>
    </div>

    <div v-if="!blogs.length && !loading">
      <h3 class="my-5">
        No content available.
      </h3>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BBadge,
  BCardImg,
  BImg,
  BOverlay,
  BPagination,
} from "bootstrap-vue";
import { get } from "lodash";
import Search from "@/assets/svg/Search.svg";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const watchHandler = {
  handler() {
    this.fetchAllData();
  },
  immediate: false,
};

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.fetchAllData();
  },
  immediate: false,
};

export default {
  name: "ListBlogs",
  components: {
    BOverlay,
    BRow,
    BCard,
    BCol,
    BBadge,
    BCardImg,
    BImg,
    Search,
    BPagination,
  },
  data() {
    return {
      loading: true,
      blogs: [],
      perPage: 1,

      debounceFetchAllData: null,
    };
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    }
  },

  watch: {
    perPage: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: 2,
          page: 1,
        };

        const request = await this.useJwt().sharedService.fetchBlogPosts(query);
        const { data, pagination } = request.data;
        this.blogs = data.slice(0, 2);

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    kFormatter,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";

.blog-title- {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-content- {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
}
</style>
