<template>
  <div class="navbar-container main-menu-content d-flex justify-content-center align-items-center" style="background-color: #1735bf;">
    <div class="d-flex align-items-center" style="gap: 80px;">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/pensa.jpg" width="60px" />
        <h1 class="text-yellow mx-2" style="color: #eae100; font-weight: bold; text-shadow: rgba(255, 255, 255, 0.1);">PENSA GHANA</h1>
      </div>
      <ul id="custom-nav" class="d-flex align-items-center justify-content-start navbar-nav">
        <li v-for="navItem of PublicMenuItems.slice(0,1)" :key="navItem.route" class="nav-item custom" :class="{'active': isActive(navItem)}">
          <b-link :to="{ name: navItem.route }" class="nav-link custom-nav-link">
            {{ navItem.title }}
          </b-link>
        </li>
        <div class="dropdown">
          <button class="dropbtn">ABOUT US</button>
          <div class="dropdown-content">
            <b-link :to="{ name: 'about-us-history' }" class="nav-link custom-nav-link">
              HISTORY
            </b-link>
            <b-link :to="{ name: 'about-us-leadership' }" class="nav-link custom-nav-link">
              LEADERSHIP
            </b-link>
            <b-link :to="{ name: 'about-us-campus' }" class="nav-link custom-nav-link">
              CAMPUS
            </b-link>
            <b-link :to="{ name: 'about-us-sectors' }" class="nav-link custom-nav-link">
              SECTOR
            </b-link>
          </div>
        </div> 
        <li v-for="navItem of PublicMenuItems.slice(5,7)" :key="navItem.route" class="nav-item custom" :class="{'active': isActive(navItem)}">
          <b-link :to="{ name: navItem.route }" class="nav-link custom-nav-link">
            {{ navItem.title }}
          </b-link>
        </li>
      </ul>

    </div>

    <!-- <div class="menu-buttons d-flex">
      <div class="login-button mr-2">
        <b-link :to="{ name: 'auth-login' }">
          Login</b-link>
      </div>

      <div class="signup-button">
        <b-link :to="{ name: 'auth-register' }">
          Sign Up
        </b-link>
      </div>
    </div> -->
  </div>
</template>

<script>
// import navMenuItems from '@/navigation/horizontal'
import { BLink, } from 'bootstrap-vue'
import PublicMenuItems from "@/navigation/public";
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    BLink,
    HorizontalNavMenuItems,
  },
  computed: {
    navigationItems() {
      return this.PublicMenuItems
    }
  },
  methods: {
    isActive(navItem) {
      return this.$route.name === navItem.route
    }
  },
  setup() {
    return {
      PublicMenuItems,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
@import '@core/scss/vue/pages/page-public.scss';

.nav-link.custom-nav-link {
  font-size: 1.1rem;
  color: white !important;
  font-weight: bold;
}

.nav-item.custom {
  // background-color: red;
  padding: 7px;
  border-radius: 10px;
}

.nav-item.custom:hover {
  // background-color: #3f4f69;
  opacity: 0.8;
}

.nav-item.custom.active {
  color: yellow;
  // background-color: #3f4f69;
  opacity: 0.8;
  a {
    color: yellow !important;
  }
}


.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #1735bf; opacity: 0.8;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #1735bf;}

/* Dropdown Button */
.dropbtn {
  background-color: #1735bf;
  color: white;
  padding: 16px;
  font-size: 1.1rem;
  color: white !important;
  font-weight: bold;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1735bf;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
</style>
